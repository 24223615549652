<template>
  <div class="buyer">
    <div class="mainbody">
      <div class="header-title">
        <p>代账客户入驻 - 证照信息 - <span class="supplier_name">{{name}}</span></p>
      </div>
      <div class="header-text">
        <p>
          因合规交易的特殊性，卖家个体户必需为小规模纳税人，若入驻的企业不符合小规模纳税人的，平台有权拒绝为其办理相关业务
        </p>
      </div>
      <div class="basic-information">
        <div class="enterprise-left">
          <div class="enterprise-information">
            <div class="information-title">
              <span>个体户信息</span>
            </div>
            <p>
              <span style="color: red">*</span>法人名称:&nbsp;
              <span>
              <a-input class="buyer-input" v-model="enterprise.person" placeholder="法人名称"
                :class="enterprise.person? 'activate-input' : ''" /><br>
                <span class="issame" v-if="enterprise.person&&enterprise.person!=name">*识别的经营者姓名与添加个人名称（{{name}}）不匹配，请确认修改</span>
              </span>
            </p>
            <p>
              <span style="color: red">*</span>主体名称:&nbsp;
              <span>
              <a-input class="buyer-input" v-model="enterprise.supplierName" placeholder="主体名称"
                :class="enterprise.supplierName? 'activate-input' : ''" /><br>
              </span>
            </p>
            <p>
              <span style="color: red">*</span>统一信用代码:&nbsp;
              <a-input @blur="onchangeCode" class="buyer-input" v-model="enterprise.unifiedCode" placeholder="统一信用代码"
                :class="enterprise.unifiedCode != '' ? 'activate-input' : ''" />
            </p>
            <p>
              <span style="color: red">*</span>注册时间:&nbsp;
              <a-date-picker class="buyer-input buyer-input-time" v-model="enterprise.licenseRegTime" placeholder="营业执照注册时间"
                :class="enterprise.licenseRegTime? 'activate-input' : ''" />
            </p>
            <p>
              <span style="color: red">*</span>经营场所:&nbsp;
              <a-input class="buyer-input" v-model="enterprise.invoiceAddr" placeholder="经营场所"
                :class="enterprise.invoiceAddr != '' ? 'activate-input' : ''" />
            </p>
            <p>
              <span style="color: red">*</span>开票电话:&nbsp;
              <a-input class="buyer-input" v-model="enterprise.phone" placeholder="开票电话"
                :class="enterprise.phone != '' ? 'activate-input' : ''" />
            </p>
            <p>
              <span style="color: red">*</span>开票行名称:&nbsp;
              <a-input class="buyer-input" v-model="enterprise.openBankName" placeholder="开票行名称"
                :class="enterprise.openBankName != '' ? 'activate-input' : ''" />
            </p>
            <p>
              <span style="color: red">*</span>开票行账号:&nbsp;
              <a-input class="buyer-input" v-model="enterprise.bankAccount" placeholder="开票行账号"
                :class="enterprise.bankAccount != '' ? 'activate-input' : ''" />
            </p>
          </div>
          <div class="contract-information">
            <div class="information-title">
              <span>区域信息</span>
            </div>
            <p style="margin-bottom:40px">
              <span style="color: red">*</span>区域编码:&nbsp;
              <span style="position:relative;">
                <a-input-search  class="buyer-input buyer-input-search" :maxLength="18"
                  v-model="enterprise.regionCode"
                  placeholder="区域编码" :class="enterprise.regionCode != '' ? 'activate-input' : ''" enter-button @search="onSearchCode" />
                <span class="msg-text">区域编码截取营业执照3-8位，请仔细核对</span>
              </span>
            </p>
            <p>
              <span style="color: red">*</span>区域名称:&nbsp;
              <a-input class="buyer-input" :maxLength="18"
                v-model="enterprise.regionName"
                placeholder="区域名称" :class="enterprise.regionName != '' ? 'activate-input' : ''" />
            </p>
          </div>
        </div>
        <div class="enterprise-right">
          <a-spin tip="识别中..." :spinning="spinning">
            <div class="permit-logo" @click="uploadocr">
              <div class="ocr">
                <input v-show="false" id="addImage" ref="addImage" alt="" class="upload" type="file" name="input"
                  multiple="multiple" accept="image/png,image/jpeg,image/gif,image/jpg" @change="getImgFile" />
                <p><img src="../../assets/images/u161.png" alt="" /></p>
                <p class="ocr-text">请上传个体户营业执照*</p>
              </div>
              <div class="ocrimg" v-show="fileName">
                <img :src="fileName" alt="" />
              </div>
            </div>
          </a-spin>
        </div>
      </div>
      <div class="xy">
        <a-button class="next close" @click="$router.push('/')"> 关闭</a-button>
        <a-button class="next" @click="next" :loading="loading"> 下一步</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { ocrIndividual, addIndApplyAndSupplier, regionsCity } from '../../api/buyer'
import { uploadPhoto } from '../../api/utils'
import moment from 'moment'

export default {
  components: {},
  data() {
    return {
      name:'',
      enterprise: {
        regionName:'',//区域名称
        regionCode:'',//区域编码
        supplierName:'',//企业名
        licenseUrl:'',//营业执照
        unifiedCode:'',//企业编码
        licenseRegTime:'',//营业执照注册时间
        invoiceAddr:'',//经营地址
        phone:'',//电话
        openBankName:'',//开票行名称
        bankAccount:'',//银行账号
        person:'',//法人名称
      },
      signer: {
        signerName: '', //签署人姓名
        signerIdCard: '', //签署人身份证号
        signerMobile: '' //签署人手机号
      },
      fileName: '', //营业执照
      error: {
        regionName:'请输入区域名称',//区域名称
        regionCode:'请输入区域编码',//区域编码
        supplierName:'请输入企业名',//企业名
        licenseUrl:'请输入营业执照',//营业执照
        unifiedCode:'请输入企业编码',//企业编码
        licenseRegTime:'请选择营业执照注册时间',//营业执照注册时间
        invoiceAddr:'请输入经营地址',//经营地址
        phone:'请输入电话',//电话
        openBankName:'请输入开票行名称',//开票行名称
        bankAccount:'请输入银行账号',//银行账号
        person:'请输入法人名称',//法人名称
      },
      loading: false,
      spinning: false
    }
  },
  watch:{
    'enterprise.regionCode':{
      handler(){
        this.enterprise.regionName = ''
      },
      immediate:true,
      deep:true
    }
  },
  created(){
    this.name = this.$route.query.name
  },
  methods: {
    //截取3-8位统一信用代码 作为区域编码
    onchangeCode(){
      if(!this.isindividual(this.enterprise.unifiedCode)){
        return
      }
      this.enterprise.regionCode = this.enterprise.unifiedCode.substr(2,6)
      this.onSearchCode()
    },
    //搜索区域编号 获取区域名称
    onSearchCode(){
      regionsCity({
        areaCode:this.enterprise.regionCode
      }).then(res=>{
        if (res.code === 200) {
          if(!res.data){
              this.$message.error('请核对编码！')
              this.enterprise.regionName = ''
              return
            }
            this.enterprise.regionName = res.data.cityName
            
          } else {
            this.$message.error(res.message)
          }
      })
    },
    //判断营业执照是否合规
    isindividual(code){
      if(code.substr(0,1)!=9){
        this.$message.error('请上传多证合一的个体户营业执照！')
        return false
      }
      if(code.substr(1,1)!=2){
        this.$message.error('暂不受理非个体户相关业务！')
        return false
      }
      return true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    getImgFile(e) {
      if (e.target.files[0]) {
        if ((e.target.files[0].size / 1024 / 1024) > 1.5) {
          this.$message.error('图片大小超过1.5MB，请进行图片压缩处理再上传识别')
          e.target.value = null
          return
        }
        this.uploadImage(e.target.files)
        e.target.value = null
      }
    },
    // 选择图片
    uploadocr() {
      this.$refs.addImage.dispatchEvent(new MouseEvent('click'))
    },
    // 上传图片
    async uploadImage(images) {
      const file = images[0]
      uploadPhoto(file, (info) => {
        this.spinning = true
        ocrIndividual({ path: info }).then(async (res) => {
          console.log(res)
          this.spinning = false
          if (res.code === 200) {
            if(!this.isindividual(res.data.reg_num)){
              return
            }
            this.enterprise.supplierName = res.data.name
            this.enterprise.unifiedCode = res.data.reg_num
            this.enterprise.invoiceAddr = res.data.address
            this.enterprise.person = res.data.person
            this.enterprise.licenseRegTime = moment(res.data.establish_date)
            this.enterprise.licenseUrl = info
            this.fileName = info
            this.onchangeCode()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 点击提交
    next() {
      let verify = false
      if (!this.enterprise.person) {
        this.$message.error(this.error.person )
      }else if (!this.enterprise.regionName) {
        this.$message.error(this.error.regionName )
      } else if (!this.enterprise.regionCode) {
        this.$message.error(this.error.regionCode)
      } else if (!this.enterprise.supplierName) {
        this.$message.error(this.error.supplierName)
      } else if (!this.enterprise.licenseUrl) {
        this.$message.error(this.error.licenseUrl)
      } else if (!this.enterprise.unifiedCode) {
        this.$message.error(this.error.unifiedCode)
      } else if (!this.enterprise.licenseRegTime) {
        this.$message.error(this.error.licenseRegTime)
      } else if (!this.enterprise.invoiceAddr) {
        this.$message.error(this.enterprise.invoiceAddr)
      } else if (!this.enterprise.phone) {
        this.$message.error(this.error.phone)
      } else if (!this.enterprise.openBankName) {
        this.$message.error(this.error.openBankName)
      }else if (!this.enterprise.bankAccount) {
        this.$message.error(this.error.bankAccount)
      }else if(this.enterprise.person!=this.name){
        return
      } else {
        verify = true
        this.loading = true
      }
      if (verify)
        return addIndApplyAndSupplier({
          ...this.enterprise,
          licenseRegTime:this.enterprise.licenseRegTime.format("YYYY-MM-DD"),
          individualId:this.$route.query.individualId
        }).then((res) => {
          this.loading = false
          if (res.code == 200) {
            this.$store.dispatch("getuserMenu")
            this.$router.replace({path: `/individualresult?id=${res.data}`})
          } else {
            this.$message.error(res.message)
          }
        }).catch(r => {
          this.loading = false
          this.$message.error(r.message)
        })
    }

  }
}
</script>

<style lang="less" scoped>
.supplier_name{
  color: #60a830;
}
.issame{
  color: red;
  margin-top: 10px;
  display: inline-block;
}
.buyer {
  margin-bottom: 100px;
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px) {
    padding: 0 15px;
  }
  margin: 20px auto;
  .mainbody {
    padding-bottom: 30px;
    background-color: #ffffff;
    .header-title {
      padding: 30px;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.847058823529412);
      font-weight: 650;
    }
    .header-text {
      padding: 0 0 0 46px;
      font-size: 16px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.647058823529412);
    }
    .basic-information {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      .enterprise-left {
        font-size: 14px;
        font-weight: 400;
        width: 470px;
        padding: 60px 0 0 0;
        text-align: right;
        
        .buyer-input {
          width: 343px;
          @media screen and (max-width: 1200px) {
            width: 220px;
          }
          height: 32px;
          border: 1px solid rgba(0, 0, 0, 0.247058823529412);
          background-color: rgba(245, 245, 245, 1);
          padding: 0 0 0 10px;
        }
        .buyer-input-search{
          border: 0;
          padding-left: 0;
          vertical-align: middle;
          
        }
        .msg-text{
          position: absolute;
          top: 40px;
          left:0px;
          color: red;
          font-size: 14px;
          white-space:nowrap
        }
        .buyer-input-time{
          border: 0;
          padding-left: 0;
          vertical-align: middle;
        }
        
        .buyer-input-time /deep/.ant-calendar-picker-input {
            width: 343px!important;
              @media screen and (max-width: 1200px) {
                width: 220px!important;
              }
              height: 32px;
              border: 1px solid rgba(0, 0, 0, 0.247058823529412);
              background-color: rgba(245, 245, 245, 1);
              padding: 0 0 0 10px;
        }
        .activate-input /deep/.ant-calendar-picker-input{
          background-color: #ffffff;
        }
        .buyer-input-search /deep/.ant-input {
             width: 297px;
              @media screen and (max-width: 1200px) {
                width: 174px;
              }
              height: 32px;
              border: 1px solid rgba(0, 0, 0, 0.247058823529412);
              background-color: rgba(245, 245, 245, 1);
              padding: 0 0 0 10px;
        }
        .activate-input /deep/.ant-input{
          background-color: #ffffff;
        }
        .activate-input {
          background-color: #ffffff;
        }
        // .enterprise-information {
        // }
        .contract-information {
          padding: 20px 0 60px 0;
        }
        .information-title {
          text-align: left;
          font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑',
            sans-serif;
          padding: 0 0 20px 45px;
          font-size: 18px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.847058823529412);
        }
        p {
          margin-bottom: 20px;
        }
      }

      .enterprise-right {
        width: 630px;
        @media screen and (max-width: 1200px) {
          transform: scale(0.8);
        }
        padding: 65px 0 0 25px;
        .permit-logo {
          cursor: pointer;
          width: 550px;
          height: 230px;
          border: 1px dashed transparent;
          background: linear-gradient(white, white) padding-box,
            repeating-linear-gradient(
              -45deg,
              #1890ff 0,
              #ccc 0.3em,
              white 0,
              white 0.7em
            );
          display: flex;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
          .ocr {
            width: 240px;
            height: 230px;
            text-align: center;
          }
          p {
            margin: 50px auto;
            width: 63px;
            height: 51px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .ocr-text {
            font-size: 14px;
            width: 240px;
          }
          .ocrimg {
            width: 240px;
            height: 230px;
            position: absolute;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .xy {
      width: 137px;
      margin: auto;
      display: flex;
    }
    .next {
      width: 137px;
      height: 40px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 16px;
      font-weight: 400;
      border-radius: 4px;
      background-color: rgba(24, 144, 255, 1);
      line-height: 40px;
      text-align: center;
      // margin: 0 auto;
      color: #ffffff;
    }
    .close{
      background-color: #ccc;
      color: #000;
    }
  }
}
/deep/.ant-spin-dot-item {
  background-color: rgba(24, 144, 255, 1);
}
/deep/.ant-spin {
  font-size: 20px;
  font-weight: 500;
  color: #1890ff;
  margin-left: -25px;
}


</style>