import request from '@/utils/request'


// 七牛云 图片上传
export function qiniu(params){
    return request({
        url:'/qiniu/token',
        method:'get',
        params
    })
}
// ocr 识别营业执照
export function ocr(params) {
    return request({
        url: '/trade/pur/ocr',    
        method: 'get',
        params   
    })
}

// ocrIndividual 个体识别营业执照
export function ocrIndividual(params) {
    return request({
        url: '/trade/supplier/ocr',    
        method: 'get',
        params   
    })
}

// regionsCity 区域所在城市数据查询
export function regionsCity(params) {
    return request({
        url: '/tax/regions/city',    
        method: 'get',
        params   
    })
}

// regionsCity 代账客户入驻申请
export function addIndApplyAndSupplier(params) {
    return request({
        url: '/trade/supplier/addIndApplyAndSupplier',    
        method: 'post',
        data:params  
    })
}

// 买家入驻
export function apply(data) {
    return request({
        url: '/trade/pur/apply',
        method: 'post',
        data
    })
}

// 买家对应的所有卖家
export function suppliers(params) {
    return request({
        url: '/trade/buyer/suppliers',
        method: 'get',
        params
    })
}

// 买家自主订单生成
export function orderAndMdse(data) {
    return request({
        url: '/orders/create/orderAndMdse',
        method: 'post',
        data
    })
}

// 获取买家预付货款配置项
export function piaConfig({purchaserId}) {
    return request({
        url: `/trade/pur/adv/config/${purchaserId}`,
        method: 'post'
    })
}

// 买家预付账号列表信息获取
export function preAcctList(data) {
    return request({
        url: '/trade/pur/adv/preAcct/list',
        method: 'post',
        data
    })
}

// 买家预付金额账户详情
export function preAcctRecord({id}) {
    return request({
        url: `/trade/pur/adv/preAcct/detail/${id}`,
        method: 'post'
    })
}

// 买家预付账号预付流水详情
export function preAcctRecordList(data) {
    return request({
        url: '/trade/pur/adv/preAcct/record',
        method: 'post',
        data
    })
}

// 获取抵扣记录
export function useList(params) {
    return request({
        url: '/trade/pur/adv/use/list',
        method: 'get',
        params
    })
}

// 列表-获取收款方信息
export function payeeList(params) {
    return request({
        url: '/trade/pur/adv/payee/list',
        method: 'get',
        params
    })
}

// 获取预付上限金额
export function payeeLimit(params) {
    return request({
        url: '/trade/pur/adv/payee/limit',
        method: 'get',
        params
    })
}

// 获取支付链接
export function payUrl(params) {
    return request({
        url: '/trade/pur/adv/pay/url',
        method: 'get',
        params
    })
}

// 关闭支付记录
export function closePay(paymentNo) {
    return request({
        url: `/trade/pur/adv/pay/close/${paymentNo}`,
        method: 'post'
    })
}